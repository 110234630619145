import { createStore } from 'solid-js/store'

export type TokenStore = ReturnType<typeof createTokenStore>

export function createTokenStore() {
  return createStore({
    isLoading: true,
    accessToken: undefined as string | undefined,
    expirationTime: undefined as Date | undefined,
    issuedAt: undefined as Date | undefined,
  })
}
