import TypedEventEmitter from 'typed-emitter'
import EventEmitter from 'eventemitter3'
import { onCleanup, onMount } from 'solid-js'

type ActionEvents<A extends Record<string, (...args: any[]) => unknown>> = {
  [K in keyof A extends string ? `before${Capitalize<keyof A>}` : keyof A]: (
    ...args: Parameters<A[K]>
  ) => void
} & {
  [K in keyof A]: (...args: Parameters<A[K]>) => void
}

const createActionEventEmitter = <
  A extends Record<string, (...args: any[]) => unknown>,
>() => {
  type Events = ActionEvents<A>

  const eventEmitter = new EventEmitter() as any as TypedEventEmitter<Events>

  const useEvent = <E extends keyof A>(eventName: E, listener: A[E]) => {
    onMount(() => eventEmitter.on(eventName, listener))
    onCleanup(() => eventEmitter.off(eventName, listener))
  }

  const useEventOnce = <E extends keyof A>(eventName: E, listener: A[E]) => {
    eventEmitter.once(eventName, listener)
    return () => eventEmitter.off(eventName, listener)
  }

  return { useEvent, useEventOnce, eventEmitter }
}

export { createActionEventEmitter }
