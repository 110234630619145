import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { AppRouter } from './server'
import SuperJSON from 'superjson'

export type Trpc = typeof trpc

export type TrpcProcedures = keyof Trpc

export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: '/api/trpc',
    }),
  ],
  transformer: SuperJSON,
})
