import './style.css'

import { Flex } from '../components/ui/Flex'
import type { JSX } from 'solid-js'
import { AuthProvider } from '#/auth/ui/AuthProvider'
import { AuthGuard } from '#/auth/ui/AuthGuard'
import { DialogProvider } from '#/components/Dialog/Dialog'
import { SubscriptionProvider } from '#/subscription/state/SubscriptionProvider'

export default function LayoutDefault(props: { children?: JSX.Element }) {
  return (
    <AuthProvider>
      <DialogProvider>
        <SubscriptionProvider>
          <AuthGuard>
            <Flex fullScreen>
              <Content>{props.children}</Content>
            </Flex>
          </AuthGuard>
        </SubscriptionProvider>
      </DialogProvider>
    </AuthProvider>
  )
}

function Content(props: { children: JSX.Element }) {
  return <Flex flex={1}>{props.children}</Flex>
}
