import { createTRPCProxyClient, httpLink } from '@trpc/client'
import { AuthRouter } from './authServer'
import SuperJSON from 'superjson'

export const authClient = createTRPCProxyClient<AuthRouter>({
  links: [
    httpLink({
      url: '/api/auth',
    }),
  ],
  transformer: SuperJSON,
})
