import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'
import { authLogger } from '../../authLogger'
import { authClient } from '../../authClient'

export function createSignInWithApple({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async () => {
    setLoading()

    const options: SignInWithAppleOptions = {
      clientId: 'com.saztunes.applesignin',
      redirectURI: `${location.origin}/auth/apple/return`,
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    }

    try {
      const result = await SignInWithApple.authorize(options)
      authLogger.info('Sign In With Apple Response:', result.response)

      const { authorizationCode, familyName, givenName, email } =
        result.response

      const { accessToken, expirationTime, issuedAt } =
        await authClient.loginWithAppleCode.mutate({
          authorizationCode,
          familyName: familyName || undefined,
          givenName: givenName || undefined,
          email: email || undefined,
        })

      authLogger.info('Successfully logged in with apple', result.response)

      handleToken({ accessToken, expirationTime, issuedAt })
    } catch (error) {
      authLogger.error('Error while logging in with apple', error)
      resetState()
    }
  }
}
