import { TokenStore } from './createTokenStore'
import { createResetState } from './createResetState'
import { createHandleToken } from './createHandleToken'
import { createRefreshAccessToken } from './createRefreshAccessToken'
import { createSignInWithApple } from './createSignInWithApple'
import { createLogout } from './createLogout'
import { createLoginWithEmailAndPassword } from './createLoginWithEmailAndPassword'
import { createRegisterWithEmailAndPassword } from './createRegisterWithEmailAndPassword'
import { createSignInWithGoogle } from './createSignInWithGoogle'
import { createWaitForAuthentication } from './createWaitForAuthentication'

export const createAuthHandlers = ([tokenState, setTokenState]: TokenStore) => {
  const setLoading = () =>
    setTokenState((state) => ({ ...state, isLoading: true }))

  const resetState = createResetState(setTokenState)

  const handleToken = createHandleToken(setTokenState)

  const signInWithApple = createSignInWithApple({
    setLoading,
    handleToken,
    resetState,
  })

  const signInWithGoogle = createSignInWithGoogle({
    setLoading,
    handleToken,
    resetState,
  })

  const logout = createLogout({ setLoading, resetState })

  const loginWithEmailAndPassword = createLoginWithEmailAndPassword({
    setLoading,
    handleToken,
    resetState,
  })

  const registerWithEmailAndPassword = createRegisterWithEmailAndPassword({
    setLoading,
    handleToken,
    resetState,
  })

  const refreshAccessToken = createRefreshAccessToken({
    setLoading,
    handleToken,
    resetState,
  })

  const waitForAuthentication = createWaitForAuthentication(tokenState)

  return {
    loginWithEmailAndPassword,
    signInWithApple,
    signInWithGoogle,
    logout,
    registerWithEmailAndPassword,
    refreshAccessToken,
    waitForAuthentication,
  }
}
