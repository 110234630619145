import { PaymentElement, useElements } from 'solid-stripe'
import { Button } from '#/components/ui/Button'
import { useSubscriptionActions } from '../state/SubscriptionProvider'

export function CheckoutForm(props: { subscriptionId: string }) {
  const getElements = useElements()

  const { confirmPayment } = useSubscriptionActions()

  async function handleSubmit(event: SubmitEvent) {
    event.preventDefault()

    const elements = getElements()

    if (!elements) return
    await confirmPayment(elements)
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type="submit">Pay</Button>
    </form>
  )
}
