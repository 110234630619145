import { useAuth } from './AuthProvider'

import { Button } from '#/components/ui/Button'

const SignInWithGoogleButton = () => {
  const auth = useAuth()
  return <Button onClick={auth.signInWithGoogle}>Sign In With Google</Button>
}

export { SignInWithGoogleButton }
