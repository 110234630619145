import { useAuth } from '#/auth/ui/AuthProvider'
import { Trpc, trpc } from '#/trpc/client'

import { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import { Accessor, createResource, onCleanup, onMount } from 'solid-js'
import { AppRouter } from './server'
import EventEmitter from 'eventemitter3'
import TypedEventEmitter from 'typed-emitter'

type KeyOfTypeTest<T, U> = NonNullable<
  {
    [K in keyof T]: [U] extends [T[K]] ? (T[K] extends U ? K : never) : never
  }[keyof T]
>

export type Queries = KeyOfTypeTest<Trpc, { query: any }>
export type QueryInput<Q extends Queries> = inferRouterInputs<AppRouter>[Q]
export type QueryOutput<Q extends Queries> = inferRouterOutputs<AppRouter>[Q]

type QueryArgs<Q extends Queries> = {
  query: Trpc[Q]['query'] extends Function ? Q : never
  needsAuth?: boolean
  fetchOnMount?: boolean
  skip?: Accessor<boolean>
} & (QueryInput<Q> extends void | undefined
  ? { input?: never }
  : { input: QueryInput<Q> | (() => QueryInput<Q>) })

const eventEmitter = new EventEmitter() as TypedEventEmitter<{
  [key in `refetch.${Queries}`]: () => void
}>

const useTrpcQuery = <Q extends Queries>({
  query,
  input,
  needsAuth,
  fetchOnMount,
  skip,
}: QueryArgs<Q>) => {
  const auth = useAuth()

  const [resource, actions] = createResource(
    () =>
      (typeof input === 'function'
        ? ([input(), skip?.()] as const)
        : ([input, skip?.()] as const)) ?? [],
    async ([input, skip]): Promise<QueryOutput<Q>> => {
      if (skip || needsAuth !== false) await auth.waitForAuthentication()
      return (trpc[query].query as Function)(input)
    },
    { ssrLoadFrom: 'initial', deferStream: true },
  )

  onMount(() => {
    if (fetchOnMount !== false) actions.refetch()
    eventEmitter.on(`refetch.${query}`, actions.refetch)
  })

  onCleanup(() => {
    eventEmitter.off(`refetch.${query}`, actions.refetch)
  })

  return [resource, actions] as const
}

const refetchTrpcQuery = (query: Queries) =>
  eventEmitter.emit(`refetch.${query}`)

export { useTrpcQuery, refetchTrpcQuery }
