import { Button as KButton } from '@kobalte/core'
import { ComponentProps, splitProps } from 'solid-js'
import styles from './Button.module.css'

const Button = (
  props: ComponentProps<typeof KButton.Root> &
    (
      | {
          isToggle: true
          isActive?: boolean
        }
      | {
          isToggle?: false
          isActive?: never
        }
    ),
) => {
  const [ownProps, rest] = splitProps(props, [
    'isToggle',
    'isActive',
    'classList',
  ])

  return (
    <KButton.Root
      {...rest}
      data-is-active={ownProps.isToggle && ownProps.isActive ? '' : undefined}
      classList={{
        [styles.Button]: true,
        ...ownProps.classList,
      }}
    />
  )
}

export { Button }
