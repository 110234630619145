import { accessTokenCookie, authCookiePaths } from '../../config'
import { TokenStore } from './createTokenStore'
import cookie from 'js-cookie'

export function createResetState(setTokenState: TokenStore[1]) {
  return () => {
    authCookiePaths.forEach((path) => {
      cookie.remove(accessTokenCookie, {
        domain: new URL(location.origin).hostname,
        path,
      })
    })

    setTokenState({
      accessToken: undefined,
      expirationTime: undefined,
      issuedAt: undefined,
      isLoading: false,
    })
  }
}
