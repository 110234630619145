import {
  Accessor,
  createContext,
  createRoot,
  JSX,
  splitProps,
  untrack,
  useContext as useSolidContext,
} from 'solid-js'
import { createModuleContextValue } from './createModuleContextValue'
import { createStore, SetStoreFunction } from 'solid-js/store'

const createModule = <
  S extends object,
  M extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends Record<string, (...args: any[]) => unknown>,
>({
  createActions,
  initialState,
  mapState,
  name,
  log,
}: {
  initialState: S
  mapState: (state: S) => Accessor<M>
  createActions: (mappedState: M, setState: SetStoreFunction<S>) => A
  name: string
  log?: typeof console.log
}) => {
  const createContextValue = (
    store: [state: S, setState: SetStoreFunction<S>],
    propState: { state?: Partial<S> },
  ) =>
    createModuleContextValue({
      store,
      createActions,
      propState,
      mapState,
      name,
      log,
    })

  const Context = createContext<ReturnType<typeof createContextValue>>()

  const ContextProvider = (props: {
    children: JSX.Element
    state?: Partial<S>
  }) => {
    const store = createStore<S>(initialState)
    const [propState] = splitProps(props, ['state'])

    return (
      <Context.Provider value={createContextValue(store, propState)}>
        {props.children}
      </Context.Provider>
    )
  }

  const useContext = () => {
    const context = useSolidContext(Context)

    if (typeof context === 'undefined') {
      throw new Error(`Context "${name}" needs to be wrapped in a provider`)
    }
    return context
  }
  const useState = () => useContext()[0]
  const useActions = () => useContext()[1]
  const useEvent = <E extends keyof A>(eventName: E, listener: A[E]) =>
    useContext()[2].useEvent(eventName, listener)
  const useEventOnce = <E extends keyof A>(eventName: E, listener: A[E]) =>
    useContext()[2].useEventOnce(eventName, listener)

  return {
    ContextProvider,
    useContext,
    useState,
    useActions,
    useEvent,
    useEventOnce,
  }
}

export { createModule }
