import { createSignal, onCleanup, onMount } from 'solid-js'

const useCurrentDate = (intervalMs = 5000) => {
  const [currentDate, setCurrentDate] = createSignal(new Date())

  let interval: NodeJS.Timeout

  onMount(() => {
    interval = setInterval(() => {
      setCurrentDate(new Date())
    }, intervalMs)
  })

  onCleanup(() => {
    clearTimeout(interval)
  })

  return currentDate
}

export { useCurrentDate }
