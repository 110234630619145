import { User } from '#/db/types/User'

const hasActiveSubscriptionUntil = (subscription: User['subscription']) => {
  return subscription &&
    ['active', 'trialing'].includes(subscription.status) &&
    subscription.currentPeriodStartsAt < new Date() &&
    subscription.currentPeriodEndsAt > new Date()
    ? subscription.currentPeriodEndsAt
    : undefined
}

export { hasActiveSubscriptionUntil }
