import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'
import { authLogger } from '../../authLogger'
import { authClient } from '../../authClient'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { google as googleConfig } from '#/auth/config'
import { Capacitor } from '@capacitor/core'

export function createSignInWithGoogle({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  const initializePromise = GoogleAuth.initialize({
    clientId:
      Capacitor.getPlatform() === 'ios'
        ? googleConfig.iosClientId
        : googleConfig.clientId,
    scopes: ['profile', 'email'],
  })

  return async () => {
    setLoading()

    try {
      await initializePromise

      GoogleAuth.signOut()
      const result = await GoogleAuth.signIn()

      const {
        authentication: { idToken },
        familyName,
        givenName,
        email,
      } = result

      const { accessToken, expirationTime, issuedAt } =
        await authClient.loginWithGoogle.mutate({
          idToken,
          familyName: familyName || undefined,
          givenName: givenName || undefined,
          email: email || undefined,
        })

      authLogger.info('Successfully logged in with Google', result)

      handleToken({ accessToken, expirationTime, issuedAt })
    } catch (error) {
      authLogger.error('Error while logging in with Google', error)
      resetState()
    }
  }
}

const isIos = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
