import { Accessor, createEffect, untrack } from 'solid-js'
import { Store, createStore } from 'solid-js/store'

const createMappedState = <T extends object, M extends object>(
  state: Store<T>,
  mapFunction: (state: T) => Accessor<M>,
): Store<M> => {
  const getMappedState = mapFunction(state)

  const [mappedState, setMappedState] = createStore(
    untrack(() => getMappedState()),
  )

  createEffect(() => setMappedState(getMappedState()))

  return mappedState
}

export { createMappedState }
