import { Dialog } from '#/components/Dialog/Dialog'
import { trpc } from '#/trpc/client'
import { QueryOutput, useTrpcQuery } from '#/trpc/useTrpcQuery'
import { createModule } from '#/utils/module/createModule'
import { loadStripe, StripeElements } from '@stripe/stripe-js'
import {
  createEffect,
  createMemo,
  createResource,
  JSX,
  onMount,
  Show,
} from 'solid-js'
import { SetStoreFunction } from 'solid-js/store'
import { SubscriptionDialogContent } from '../ui/SubscriptionDialog'
import { useCurrentDate } from '#/utils/useCurrentDate'
import { useAuth } from '#/auth/ui/AuthProvider'
import { hasActiveSubscriptionUntil } from '../util/hasActiveSubscription'

type InitialState = typeof initialState
type SubscriptionState = ReturnType<ReturnType<typeof mapState>>

const initialState = {
  selectedPlanId: undefined as string | undefined,
  isMutationLoading: false,
  subscriptionId: undefined as string | undefined,
  paymentIntentClientSecret: undefined as string | undefined,
  isSubscriptionLoading: true,
  isDialogOpen: false,
  subscription: undefined as undefined | QueryOutput<'getSubscriptionStatus'>,
}

const mapState = (state: InitialState) => {
  const updateIntervalDate = useCurrentDate(60 * 60 * 1000)
  const hasActiveSubscription = () => {
    updateIntervalDate()

    return state.isSubscriptionLoading
      ? undefined
      : !!hasActiveSubscriptionUntil(state.subscription)
  }

  const [getPlans] = useTrpcQuery({
    query: 'getPlans',
    skip: () => hasActiveSubscription() !== false,
  })

  const [getStripe, { refetch: fetchStripe }] = createResource(
    () =>
      loadStripe(
        'pk_test_51Pt5AfRob390Yc5yoRnHlFMOXxQQXmCe0gxfvlcBY8bHTOh43O67O8GyQ3H0UAXK9Rb2YhVLp9gyeLBKYTAji7Qf00pRkxrmnA',
      ),
    { ssrLoadFrom: 'initial', initialValue: undefined },
  )

  createEffect(() => {
    if (hasActiveSubscription() === false) {
      fetchStripe()
    }
  })

  return createMemo(() => {
    return {
      ...state,
      selectedPlanId: state.selectedPlanId ?? getPlans()?.[0]?.id,
      plans: getPlans(),
      stripe: getStripe(),
      isPlansLoading: getPlans.loading,
      isStripeLoading: getStripe.loading,
      isLoading: getPlans.loading || getStripe.loading,
      hasActiveSubscription: hasActiveSubscription(),
    }
  })
}

const {
  ContextProvider,
  useActions: useSubscriptionActions,
  useState: useSubscriptionState,
  useEvent: useSubscriptionEvent,
} = createModule({
  initialState,
  log: console.log,
  createActions: (
    state: SubscriptionState,
    setState: SetStoreFunction<InitialState>,
  ) => {
    const auth = useAuth()

    onMount(async () => {
      setState({ isSubscriptionLoading: true })

      await auth.waitForAuthentication()

      setState({
        subscription: await trpc.getSubscriptionStatus.query(),
      })

      setState({ isSubscriptionLoading: false })
    })

    const startCheckout = async () => {
      if (!state.selectedPlanId) throw new Error('No plan selected')

      setState({ isMutationLoading: true })

      const { subscriptionId, paymentIntentClientSecret } =
        await trpc.startCheckout.mutate({ planId: state.selectedPlanId })

      setState({
        subscriptionId,
        paymentIntentClientSecret,
        isMutationLoading: false,
      })
    }

    const handleCheckoutSucceeded = async ({
      paymentIntentId,
      subscriptionId,
    }: {
      paymentIntentId: string
      subscriptionId: string
    }) => {
      setState({ isMutationLoading: true })

      const subscription = await trpc.handleSuccess.mutate({
        paymentIntentId,
        subscriptionId,
      })

      await auth.refreshAccessToken()

      setState({ subscription, isMutationLoading: false })

      actions.setIsDialogOpen(false)
    }

    const setSelectedPlanId = (selectedPlanId: string) =>
      setState({ selectedPlanId })

    const confirmPayment = async (elements: StripeElements) => {
      setState({ isMutationLoading: true })
      if (!state.stripe) throw new Error('Stripe not loaded')
      if (!state.subscriptionId) throw new Error('Subscription Id not set')

      const result = await state.stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${location.origin}/subscription/success?subscriptionId=${state.subscriptionId}&returnRoute=${location.pathname}`,
        },
      })

      if (result.error) {
        console.log('payment failed', result.error)
      } else {
        await handleCheckoutSucceeded({
          paymentIntentId: result.paymentIntent.id,
          subscriptionId: state.subscriptionId,
        })
      }
      setState({ isMutationLoading: false })
      return result
    }

    const setIsDialogOpen = (isDialogOpen: boolean) => {
      setState({ isDialogOpen })
    }

    const actions = {
      startCheckout,
      setIsDialogOpen,
      handleCheckoutSucceeded,
      setSelectedPlanId,
      confirmPayment,
    }

    return actions
  },
  mapState,
  name: 'subscription',
})

const SubscriptionDialog = () => {
  const subscription = useSubscriptionState()
  const { setIsDialogOpen } = useSubscriptionActions()

  return (
    <Dialog
      isLoading={subscription.isLoading}
      isOpen={subscription.isDialogOpen}
      onOpenChange={(isDialogOpen) => setIsDialogOpen(isDialogOpen)}
      content={<SubscriptionDialogContent />}
      title="Start subscription"
    />
  )
}

const SubscriptionProvider = (props: { children: JSX.Element }) => {
  return (
    <ContextProvider>
      <SubscriptionDialog />
      {props.children}
    </ContextProvider>
  )
}

export {
  SubscriptionProvider,
  useSubscriptionActions,
  useSubscriptionState,
  useSubscriptionEvent,
}
